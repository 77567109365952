import React from "react";
import "./Diseño.css";

const Diseño = () => {

  return (
    <div>Holaaaa aquí diseño web

      <p>I've been in love with visual expression for ideas like films or artwork for music. It can really take you into another universe.
      But the way I came to really apreciate web design, and to fall in love with the transmission of ideas, commmunication in miltimedia forms and design in general, was trying to express my own thoughts and ideas through this blank page,
      where 
      </p>

    </div>
    
  );
};

export default Diseño;