import React, { useEffect } from "react";
import "./Short.css";
import Video from "../img/Video.mp4";
import Poster from "../img/Poster.png";
import ig from "../img/instagram.svg";

const Short = () => {
  useEffect(() => {
    // Cambiar el color del tema en el DOM
    const themeColor = '#1d1d1d'; // Color azul
    document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);

    // Limpieza al desmontar el componente
    return () => {
      // Restaurar el color del tema al desmontar el componente, si es necesario
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#default-color');
    };
  }, []); // Se ejecuta solo una vez al montar el componente

  return (
    <div className="short">
      <div className="wrapper">
      {/* <video  id="background-video"
      className="background-video"
      defaultmuted muted autoplay="autoplay"  loop="true" playsinline poster={Poster} src={Video} type="video/mp4"/> */}
         {/* <source src={Video} type="video/mp4"/> */}
         {/* </video> */}

        <video 
        className="background-video" 
        autoplay="autoplay"
        playsinline="playsinline"
        muted="true"
        loop 
        webkit-playsinline
        playsInline
        src={Video}
        type="video/mp4">
        </video>
      
</div>
      <div className="Header"><div><p className="studios"><em>INNERTWIST</em> Studios</p></div><div><p className="cta"><a href="https://www.youtube.com/watch?v=vTHW212gHqE">Watch the film</a></p></div>
      </div>
      {/* <script>
      document.getElementById('video').play();
      </script> */}

      <div className="texto"><h4>EL EDIFICIO MÁS ALTO DEL MUNDO</h4>
      <p className="texto_sub">Short film created for the contest GEN:48 by Runway.
        <br>
        </br>
        The video was created with text-to-video tools using generative AI. <p></p>
        <br>
        </br>
        {/* <a href="/#"><b><u>Learn more about the creation process.</u></b></a> */}
        
      </p>
      </div>
      
      <div className="Footer"><div><a href="https://instagram.com/innertwistt/"><img className="ig" alt="ig" src={ig}/></a></div>
      </div>
    </div>
    
  );
};

export default Short;